.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

#header{
  background: rgb(3, 72, 73);
  background-image: url(/threekit_logo_inverted.svg);
  background-repeat: no-repeat;
  background-size: auto 30px;
  background-position-y: center;
  background-position-x: 30px;
}
#header-img{
  background: #ffffff;
  /* background-image: url(/moss-logo.png); */
  /* background-repeat: no-repeat; */
  width: 150px;
  /* background-size: auto 50px; */
  /* background-position-y: center; */
  /* background-position-x: 30px; */
}
.App-link {
  color: #09d3ac;
}

.moss-detail{
  width: 50%;
  /* max-width: 80%; */
  margin-left: 35px;
  /* margin: auto; */
}

.moss-mobile-det{
  width:80%
}